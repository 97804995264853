/* eslint-disable no-param-reassign */
import { cloneDeep } from 'lodash';
import enumProductCategories from '../enum/enumProductCategories';
import { useAppContext } from '../store/AppContext';
import getVatAmount from '../utils/price/getVatAmount';
import closeModal from '../utils/modal/closeModal';

const getCartId = (item) => `${item?.offer?.supplierCode}_${item?.product?.ean || item?.product?.eanCode}`;

const addOrUpdate = (updatedProducts, item, isExpressDelivery = false) => {
  const existingIndex = updatedProducts?.length
    ? updatedProducts.findIndex((prod) => getCartId(prod) === getCartId(item))
    : -1;
  if (existingIndex > -1) {
    // existing product : update quantity
    updatedProducts[existingIndex].quantity += item.quantity;
    // Only used to sort products in cart
    updatedProducts[existingIndex].expressDelivery =
      updatedProducts[existingIndex].expressDelivery || isExpressDelivery;
  } else {
    // add new product
    updatedProducts.push({
      cartId: getCartId(item),
      product: item?.product,
      offer: item.offer,
      category: item.category,
      catalogueModule: item.catalogueModule,
      categoryId:
        item.category === 'collectibles'
          ? 1
          : enumProductCategories.find((cat) => item.category.startsWith(cat.key))?.id,
      quantity: item.quantity,
      expressDelivery: isExpressDelivery || false,
      bigVolumeMinimum: item.offer.bigVolumeMinimum,
    });
  }
};

const useCart = (offer = null, product = null) => {
  const [{ user, cart }, stateDispatch] = useAppContext();

  const isCartPopulated = cart?.products?.length > 0;

  const getProductsQuantityBySupplier = (initialQuantity = 0) => {
    if (offer == null) return 0;
    const productsBySupplier = cart?.products?.filter((item) => item.offer?.code === offer.code);
    let sum = initialQuantity;
    if (productsBySupplier?.length) sum = productsBySupplier.reduce((acc, item) => acc + +item.quantity, sum);
    return sum || 0;
  };

  const hasReachedBigVolumeMinimum = (cartProducts = [], initialQuantity = 0) => {
    if (offer?.bigVolumeMinimum > 0) {
      const total = cartProducts.reduce((acc, elem) => {
        if (elem.offer?.supplierCode === offer.supplierCode) {
          return elem.quantity + acc;
        }
        return acc;
      }, initialQuantity);
      return total >= offer.bigVolumeMinimum;
    }
    return false;
  };

  /**
   *
   * @param {String} category
   * @param {Number} quantity
   * @param {Boolean} isExpressDelivery
   * @param {String} catalogueModule
   */
  const addToCart = (category, quantity, isExpressDelivery, catalogueModule) => {
    const item = {
      offer,
      product,
      category,
      quantity,
      catalogueModule,
    };
    let initialCart = {};

    if (cart?.paid !== true) {
      initialCart = cart;
    }

    const updatedProducts = initialCart?.products?.length ? cloneDeep(cart.products) : [];
    addOrUpdate(updatedProducts, item, isExpressDelivery);

    stateDispatch({
      type: 'SET_CART',
      payload: {
        ...initialCart,
        products: updatedProducts,
        owner: user?.email,
      },
    });

    stateDispatch({
      type: 'SET_NEW_ITEM_ADDED',
      payload: updatedProducts.find((elem) => getCartId(item) === getCartId(elem)),
    });
  };

  /**
   *
   * @param {Number} quantity
   */
  const setCartQuantity = (quantity) => {
    let clonedProducts = cloneDeep(cart?.products) || [];

    clonedProducts = clonedProducts.map((clonedProd) => {
      if (getCartId(clonedProd) === getCartId(product)) {
        return {
          ...clonedProd,
          quantity,
        };
      }
      return clonedProd;
    });

    stateDispatch({
      type: 'SET_CART',
      payload: {
        ...cart,
        products: clonedProducts,
      },
    });
  };

  /**
   *
   * @param {Object} itemToRemove
   */
  const removeFromCart = (itemToRemove) => {
    if (isCartPopulated) {
      const updatedProducts = cloneDeep(cart.products);
      const productIndex = updatedProducts.findIndex((prod) => getCartId(prod) === getCartId(itemToRemove));

      if (productIndex > -1) {
        updatedProducts.splice(productIndex, 1);
        closeModal(`removeItemFromCart_${getCartId(product)}`);
        stateDispatch({
          type: 'SET_CART',
          payload: {
            ...cart,
            products: updatedProducts,
          },
        });
      }
    }
  };

  const getCartOrderItems = () =>
    cart?.products?.map((elem) => ({
      stockId: elem.offer?.code || elem.offer?.stockId,
      quantity: elem.quantity,
      expressDelivery: elem.expressDelivery,
      tags: elem.offer?.tags,
      catalogueModule: elem.catalogueModule,
    }));

  const cartOrderComputedKey = () =>
    getCartOrderItems()
      ?.map((item) => `${item.stockId}.${item.quantity}.${item.expressDelivery}`)
      ?.join('-');

  const getTotalVatExcl = () => {
    let sum = 0;
    if (isCartPopulated) {
      sum = cart?.products.reduce((acc, val) => {
        const quantity = val.quantity || 0;
        const unitPrice = val.offer?.indicativeHtPrice || 0;
        return acc + quantity * unitPrice;
      }, sum);
    }
    return sum;
  };

  const getTotalAll = (totalDeliveryCosts) => {
    const deliveryCosts = totalDeliveryCosts || 0;
    const total = getTotalVatExcl() + deliveryCosts + getVatAmount(getTotalVatExcl() + deliveryCosts);
    return cart?.discount?.totalDiscount ? total - cart.discount.totalDiscount : total;
  };

  return {
    addToCart,
    getCartOrderItems,
    getCartId,
    getProductsQuantityBySupplier,
    hasReachedBigVolumeMinimum,
    getTotalVatExcl,
    getTotalAll,
    removeFromCart,
    isCartPopulated,
    cart,
    setCartQuantity,
    cartOrderComputedKey,
  };
};

export default useCart;
